import React from "react";
import "../styles/design.css";
function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <img src={require("../assets/img/footer_logo.png")} alt="로고" />
        <div className="footer_header">
          <div className="inner">
            <ul>
              <li className="px-2">
                <a href="./terms_use">이용약관</a>
              </li>
              <li className="px-2">
                <a href="./terms_private">개인정보처리방침</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_body">
          <div className="inner">
            <div className="footer_btm">
              <p className="company-info text-semidark">
                <br />
                사업자등록번호 : 889-81-02109
                <br />
                대표 : 고재성
                <br />
                주소 : 서울특별시 강남구 테헤란로 82길 15, 1086호(대치동,
                디아이타워)
                <br />
                전화 : 02-529-5700, 0507-1398-9029
                <br />
                E-mail : kjs76371204@naver.com
                <br />
                고객문의 : 02-529-5700, 0507-1398-9029
                <br />
                <br />
                <span className="fw_200">
                  Copyright(c) 회원권마켓 All rights reserved
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
