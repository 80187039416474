import React, {useState} from 'react';
import '../styles/select.css';

function SelectBox({list, setSelect, select}) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="selectWrap">
        <p className="selectText" onClick={() => setOpen(!open)}>
          {select}
        </p>
      </div>
      {open && (
        <div className="selectItemWrap">
          {list.map(item => {
            return (
              <p
                className="selectItemText"
                onClick={() => {
                  setSelect(item);
                  setOpen(false);
                }}>
                {item}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SelectBox;
