import axios from 'axios';
import qs from 'qs';

//테스트서버
// export const pdfURL = 'https://api.kimchair.dmonster.kr/api/view';
// export const baseURL = 'https://api.kimchair.dmonster.kr/api/front';
//실서버
export const pdfURL = 'https://api.members-market.com/api/view';
export const baseURL = 'https://api.members-market.com/api';
export const secretKey = 'F0FANYmvjijUpdjnfKmBA';
export const Authorization = 'Bearer KOyjvCnrlLHrkZDtjKjrsIDrpqx0b0FQSUtFWQ';

export const API = axios.create({
  baseURL: `/`,
  // timeout: 5000,
  // timeoutErrorMessage: '요청 시간 초과',
  headers: {'Content-Type': 'application/json'},
});
const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
});

export const customAxios = async (postData, apiUrl) => {
  //respone 데이터 반환

  const {data} = await axios({
    method: 'POST',
    url: apiUrl,
    data: postData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization,
    },
  });

  return data;
};

export const customGetAxios = async apiUrl => {
  //respone 데이터 반환

  const {data} = await axios({
    method: 'GET',
    url: apiUrl,
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      Authorization: Authorization,
    },
  });

  return data;
};

export default AxiosInstance;
