// src/components/Pagination.js
import React from 'react';
import '../styles/pagination.css';
const Pagination = ({
  totalPosts,
  postsPerPage,
  currentPage,
  paginate,
  setPageGroup,
  pageGroup,
  totalPages,
}) => {
  const pageNumbers = [];
  const pagesToShow = 5;

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const startPage = (pageGroup - 1) * pagesToShow + 1;
  const endPage = startPage + pagesToShow - 1;

  return (
    <nav>
      <ul className="paginationWrap">
        {pageGroup > 1 && (
          <li className="page-item">
            <a
              onClick={() => setPageGroup(pageGroup - 1)}
              // href="!#"
              className="page-link">
              &lt;
            </a>
          </li>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map(number => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
        {endPage < totalPages && (
          <li className="page-item">
            <a
              onClick={() => setPageGroup(pageGroup + 1)}
              // href="!#"
              className="page-link">
              &gt;
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
