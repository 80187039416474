import React, {useEffect, useState} from 'react';
import '../styles/design.css';
import '../styles/quote.css';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import {
  API,
  Authorization,
  baseURL,
  customAxios,
  customGetAxios,
} from '../api/instance';
import Pagination from '../Components/pagination';
import SelectBox from '../Components/SelectBox';
import axios from 'axios';
// import { customAxios } from "../api/instance";

function QuotePage() {
  const tableHeader = [
    {
      id: 0,
      name: '골프회원명',
      width: '32%',
    },
    {
      id: 1,
      name: '즉시매도가',
      width: '18%',
    },
    {
      id: 2,
      name: '즉시매입가',
      width: '18%',
    },
    {
      id: 3,
      name: '실거래가',
      width: '18%',
    },
    {
      id: 4,
      name: '등락',
      width: '14%',
    },
  ];
  // const
  const [selectState, setSelectState] = useState('전체');
  const [searchState, setSearchState] = useState(false);
  const [localNum, setLocalNum] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [allCount, setAllCount] = useState(0);
  const [RegionList, setRegionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);
  const [postsPerPage] = useState(10);
  const totalPages = Math.ceil(allCount / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const [headerOn, setHeaderOn] = useState(false);

  const selectList = [
    '전체',
    '무기명',
    '수도권 남부',
    '수도권 북부',
    '충청권',
    '강원권',
    '경상권',
    '전라권',
    '제주권',
  ];
  const paginate = pageNumber => setCurrentPage(pageNumber);
  useEffect(() => {
    onHandleHomeMyList();
  }, [currentPage, localNum, searchState]);

  useEffect(() => {
    setCurrentPage(1);
    if (selectState == '전체') {
      setLocalNum('all');
    } else if (selectState == '무기명') {
      setLocalNum('1');
    } else if (selectState == '수도권 남부') {
      setLocalNum('2');
    } else if (selectState == '수도권 북부') {
      setLocalNum('3');
    } else if (selectState == '충청권') {
      setLocalNum('4');
    } else if (selectState == '강원권') {
      setLocalNum('5');
    } else if (selectState == '경상권') {
      setLocalNum('6');
    } else if (selectState == '전라권') {
      setLocalNum('7');
    } else if (selectState == '제주권') {
      setLocalNum('8');
    }
  }, [selectState]);
  const handleKeyDown = e => {
    console.log(e.key);
    if (e.key === 'Enter') {
      // enter 했을 때의 코드 작성
      // if(e.keyCode === 13) 도 사용가능하다.
      onSearch();
    }
  };
  const onSearch = () => {
    setSearchState(true);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   getTest();
  // }, []);
  // const getTest = async () => {
  //   console.log('vvv');
  //   try {
  //     const res = await customGetAxios('/front/footer');
  //     if (res.result == true) {
  //       console.log('푸터 res', res);
  //     }
  //   } catch (error) {
  //     // dispatch(LoadingAction.toggleLoading(false));
  //     console.log(error);
  //   }
  // };
  //시세표
  const onHandleHomeMyList = async () => {
    // const res = await API.post('/front/quote/list', {
    //   data: {
    //     pg: currentPage,
    //     search_txt: searchText,
    //     search_area: localNum == 0 ? 'all' : localNum,
    //   },
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    //     // 'Access-Control-Allow-Headers':
    //     //   'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    //     'Content-Type': 'application/json',
    //     Authorization: Authorization,
    //   },
    // });
    // console.log('res', res);
    await axios({
      method: 'POST',
      url: 'https://api.members-market.com/api/front/quote/list',
      headers: {
        // 'Access-Control-Allow-Methods': 'POST',
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: Authorization,
      },
      data: {
        pg: currentPage,
        search_txt: searchText,
        search_area: localNum == 0 ? 'all' : localNum,
      },
    })
      .then(res => res.data)
      .then(res => {
        console.log('res', res);
        if (res.result == true) {
          console.log('시세표 res', res);
          setSearchState(false);
          setRegionList(res.data.list);
          setAllCount(res.data.all_count);
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log('error : ', error);
      });
  };
  // const onHandleHomeMyList = async () => {
  //   const postData = {
  //     pg: currentPage,
  //     search_txt: searchText,
  //     search_area: localNum == 0 ? 'all' : localNum,
  //   };
  //   try {
  //     const res = await customAxios(postData, '/quote/list');
  //     if (res.result == true) {
  //       console.log('시세표 res', res);
  //       // setSelectState(false);
  //       setSearchState(false);
  //       // if (page == 1) {
  //       setRegionList(res.data.list);
  //       setAllCount(res.data.all_count);
  //       // } else {
  //       //   // setRegionList([...RegionList, ...res.data.list]);
  //       // }

  //       // setRegionList(res.data.list);
  //     } else {
  //       // ShowToast(res.data.msg);
  //       // setRegionList([]);
  //     }
  //     // dispatch(LoadingAction.toggleLoading(false));
  //   } catch (error) {
  //     // dispatch(LoadingAction.toggleLoading(false));
  //     console.log(error);
  //   }
  // };
  const onHandleKakao = async () => {
    window.open('http://pf.kakao.com/_nxkzPG/chat');
  };
  return (
    <div>
      <Header />
      <div className="QuoteTableWrap">
        <div className="QuoteTopWrap">
          <SelectBox
            list={selectList}
            select={selectState}
            setSelect={setSelectState}
          />
          <div className="searchWrap">
            <input
              className="sarchInputBox"
              placeholder="골프장 입력"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => handleKeyDown(e)}
            />
            <button onClick={() => onSearch()} className="SearchBtn">
              검색
            </button>
          </div>
        </div>

        <div className="queteHeaderWrap">
          {tableHeader.map(item => {
            return (
              <div
                className="tableHeader"
                style={{width: item.width}}
                key={item.id}>
                <p className="tableHeaderText">{item.name}</p>
              </div>
            );
          })}
        </div>

        <div className="queteContentWrap">
          {RegionList.map((item, index) => {
            return (
              <div className="quoteItemWrap" key={index}>
                <div
                  style={{width: tableHeader[0].width}}
                  className="quoteItem">
                  <p className="quoteItemText">{item.gmt_golf_name}</p>
                </div>
                <div
                  style={{width: tableHeader[1].width}}
                  className="quoteItem">
                  <p className="quoteItemText">{item.gmt_now_buy_price}</p>
                </div>
                <div
                  style={{width: tableHeader[2].width}}
                  className="quoteItem">
                  <p className="quoteItemText">{item.gmt_now_sale_price}</p>
                </div>
                <div
                  style={{width: tableHeader[3].width}}
                  className="quoteItem">
                  <p className="quoteItemText">{item.gmt_deal_price}</p>
                </div>
                <div
                  style={{width: tableHeader[4].width}}
                  className="quoteItem">
                  {item.gmt_deal_quto == 1 ? (
                    <img
                      className="quoteItemUpdownImg"
                      src={require('../assets/img/ico_up.png')}

                      // width={20}
                      // height={20}
                    />
                  ) : item.gmt_deal_quto == 2 ? (
                    <img
                      src={require('../assets/img/ico_down.png')}
                      className="quoteItemUpdownImg"
                      // width={20}
                      // height={20}
                    />
                  ) : (
                    <img
                      src={require('../assets/img/ico_same.png')}
                      className="quoteItemUpdownImg"
                    />
                  )}
                  <p
                    style={{
                      paddingTop: '5px',
                      color:
                        item.gmt_deal_quto == 1
                          ? '#EF5350'
                          : item.gmt_deal_quto == 2
                          ? '#1D96FF'
                          : '',
                    }}
                    className="quoteItemText">
                    {item.gmt_deal_quto == 1
                      ? '+'
                      : item.gmt_deal_quto == 2
                      ? '-'
                      : ''}
                    {item.gmt_deal_quto_price}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={allCount}
          currentPage={currentPage}
          paginate={paginate}
          setPageGroup={setPageGroup}
          pageGroup={pageGroup}
          totalPages={totalPages}
        />
        <div className="BtnBox">
          <div className="telBox">
            <a href={'tel:02-529-5700'} className="btnText">
              전화 상담
            </a>
          </div>
          <div className="kakaoBox">
            <p className="btnText" onClick={() => onHandleKakao()}>
              카톡상담
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default QuotePage;
