import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../styles/boot_custom.css';

function TermPage() {
  return (
    <div>
      <Header />

      <div className="container sub_page">
        <div>
          <h2 className="terms_tit">이용약관</h2>
          <div className="terms_cont">
            <p>
              <strong>&lsquo;회원권마켓&rsquo; 이용약관</strong>
            </p>

            <p>&nbsp;</p>

            <p>제 1조 [목적]</p>

            <p>
              본 약관은 회원권마켓(이하&ldquo;회사&rdquo;라 한다)을 사용하려는
              모든 이용객(이하&ldquo;이용자&rdquo;라 한다)간의 회사가 제공하는
              &lsquo;회원권정보 서비스&rsquo;를 회사와 이용자간의 권리, 의무 및
              책임사항을 규정함을 목적으로 합니다.
            </p>

            <p>&nbsp;</p>

            <p>제 2조 [약관의 명시 및 효력]</p>

            <p>
              ① 본 약관은 &lsquo;회사&rsquo;가 제공하는 앱 서비스 안에 명시하고
              이용자에게 공지함으로써 효력을 발생합니다.
            </p>

            <p>
              ② 본 약관은 서비스개선의 사유나 기타 필요하다고 인정되는 사유가
              발생할 경우에는 약관의 일부 또는 전부를 변경할 수 있으며 이 경우
              모든 이용자에게 공지하도록 합니다.
            </p>

            <p>
              ④ &lsquo;이용자&rsquo;가 약관의 변경되었음이 공지된 이후에도
              이의하지 아니하고 계속적으로 서비스를 이용하는 경우에는 약관의
              변경사항에 동의한 것으로 간주합니다.
            </p>

            <p>&nbsp;</p>

            <p>제 3조 [약관의 성립]</p>

            <p>
              &lsquo;회사&rsquo;와 이용자간의 계약은 이용자가 앱 서비스의 정보
              및 알림 공지를 제공받고자 성함, 연락처를 입력하고 확인 절차를 마친
              때에 성립됩니다.
            </p>

            <p>&nbsp;</p>

            <p>제 4조 [서비스의 이용범위 및&nbsp;내용]</p>

            <p>
              ① &lsquo;회사&rsquo;가 제공하는 회원권정보 및 매물은 모든
              회원권거래소가 제공하는 시세정보를 토대로 제공됩니다.
            </p>

            <p>
              ②&rsquo;이용자&rsquo; 상호간에 회원권 매물에 대한 거래가 이루어질
              수 있도록 &lsquo;회사&rsquo;는 이용자의 &lsquo;구매가&rsquo;와
              &lsquo;판매가&rsquo;를 즉시 체결하는 방식을 제공합니다.
            </p>

            <p>
              ③ &lsquo;회사&rsquo;가 제공하는 모든 회원권정보 및 매물은 참고
              용도로만 사용할 수 있으며, 이용자가 &lsquo;회사&rsquo;를 통하여
              얻은 자료는 본인 스스로가 판단하여 선택 또는 게재 할 수 있습니다.
              단, 자료를 통해서 입은 피해는 &lsquo;회사&rsquo;가 책임지지
              않습니다.
            </p>

            <p>
              ④ 이용자는 &lsquo;회사&rsquo;가 제공하는 모든 서비스를 이용할 수
              있고 &lsquo;회사&rsquo;는 이용자가 입력한 성함, 연락처를 기반으로
              정보 알림 등의 용도로 문자메시지 및 디바이스에 내장된 푸쉬알림,
              결제서비스를 이용자에게 발송할 수 있습니다.
            </p>

            <p>&nbsp;</p>

            <p>제 5조 [서비스의 변경 및 중지]&nbsp;</p>

            <p>
              ① &lsquo;회사&rsquo;는 상당한 이유가 있는 경우에 운영상, 기술상의
              필요에 따라 제공하고 있는 서비스를 변경하거나 중지 할 수
              있습니다.&nbsp;
            </p>

            <p>
              ② &lsquo;이용자&rsquo;가 &lsquo;회사&rsquo;에서 제공하는 앱
              서비스의 정상적인 운영을 방해하는 행위, 또는 그 우려가 있는 행위
              서비스를 중지 할 수 있습니다.
            </p>

            <p>
              ③ &lsquo;이용자&rsquo;가 본 서비스를 통해 &lsquo;회사&rsquo;또는
              제3자의 재산권등 기타의 권리를 침해하는 행위, 서비스를 중지 할 수
              있습니다.
            </p>

            <p>&nbsp;</p>

            <p>제 6조 [&lsquo;회원권매물&rsquo;의 관리 및 제재]</p>

            <p>
              &lsquo;회사&rsquo;는 다음과 같은 내용의 이유로 게시물의 삭제 및
              변경 할 수 있습니다.
            </p>

            <p>
              ① 시세와 맞지 않는 &lsquo;허위매물&rsquo;및
              &lsquo;허위회원권&rsquo;일 경우&nbsp;
            </p>

            <p>② 이용자의 중복작성 및 오류라고 판단되는 매물</p>

            <p>③ 성함 및 연락처가 허위 또는 불분명한 경우</p>

            <p>&nbsp;</p>

            <p>제 7조 [매매 거래시 &lsquo;회사&rsquo;의 책임 범위]&nbsp;</p>

            <p>
              &lsquo;회사&rsquo;가 제공하는 시스템을 통하여 이루어지는
              &lsquo;이용자&rsquo;간의 거래와 관련하여 구매의사와 판매의사의
              존부 및 진정성, 매물등록의 진위 여부를 &lsquo;회사&rsquo;가
              확인하고 검수하는바, 이용자는 이를 판단하고 결정합니다. 따라서,
              &lsquo;이용자&rsquo; 등록 물건에 대한 진위 여부는
              &lsquo;회사&rsquo;의 검수 과실로 인한 책임을 부담하나 이용자의
              변심 및 정보를 고의적으로 속일 경우 &lsquo;회사&rsquo;는 보증하지
              아니하며 그 귀책사유를 물을 수 없습니다.
            </p>

            <p>&nbsp;</p>

            <p>제 8조 [&lsquo;위임&rsquo;에 관한 의무]&nbsp;</p>

            <p>
              &lsquo;이용자&rsquo;간의 매매계약 체결 시 &lsquo;회사&rsquo;는
              그의 따른 제반 사항에 대하여 이용자에게 위임을 받고 대행업무를
              처리합니다
            </p>

            <p>
              &lsquo;위임&rsquo;&rsquo;이라 함은 아래와 같은 내용의 범위만을
              지칭합니다.
            </p>

            <p>
              - &lsquo;이용자&rsquo;의 매매에 필요한 제반 서류 즉, 인감날인,
              필요서류 픽업을 하는 행위
            </p>

            <p>
              - &lsquo;이용자&rsquo; 간의 서류를 취합하여 골프장에 명의개서
              접수를 하는 행위
            </p>

            <p>
              -&lsquo;이용자&rsquo;의 취득세,양도세등 세금관련 신고를 대행하는
              행위
            </p>

            <p>&nbsp;</p>

            <p>제 9조 [거래계약의 해지 또는 취소]&nbsp;</p>

            <p>
              ①&lsquo;이용자&rsquo;간의 계약의 해지 또는 취소는 상호간 합의 하에
              이루어진 경우 거래계약이 해지된 것으로 봅니다.
            </p>

            <p>
              ②&rsquo;판매자&rsquo;의 매물이 거짓일 경우 &lsquo;구매자&rsquo;는
              일방적으로 거래계약을 취소 할 수 있습니다.
            </p>

            <p>
              ③&rsquo;구매자&rsquo;가 매매계약 체결을 행하고 계약금을 15일안에
              지불하지 않는 경우 &lsquo;판매자&rsquo;가 일방적 계약을 취소 할 수
              있습니다.
            </p>

            <p>&nbsp;</p>

            <p>제 10조 [개인정보보호 의무]&nbsp;</p>

            <p>
              &lsquo;회사&rsquo;는 제공하는 서비스를 통하여
              &lsquo;이용자&rsquo;의 성함 및 연락처를 수집할 수 있으며
              &lsquo;개인정보보호법&rsquo;등 관계법령이 정하는 바에 따라
              &lsquo;이용자&rsquo;의 개인정보를 보호하기 위하여 노력합니다.
            </p>

            <p>
              개인정보 관련해서 &lsquo;회사&rsquo;의 정책은 따로 표기하여 사용
              하고 있습니다.
            </p>

            <p>-앱 서비스 내에 &lsquo;개인정보활용동의서&rsquo; 참조</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>제 11조 [기타]</p>

            <p>
              ① 이 약관에 명시되지 아니한 사항 또는 이 약관의 해석상의 다툼이
              있는 사항에 대하여 &lsquo;회사&rsquo;와 &lsquo;이용자&rsquo;가
              합의하여 결정하되, 합의가 이루어지지 않은 경우에는 관계법령 및
              공정한 일반관행에 따릅니다.&nbsp;
            </p>

            <p>
              ② 이 약관과 관련된 분쟁에 관한 소는 민사소송법상의 관할 법원에
              제기합니다.
            </p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermPage;
