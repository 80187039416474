import React, {useEffect, useState} from 'react';
import '../styles/design.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../Components/Footer';
import {useNavigate} from 'react-router-dom';
import logoImg from '../assets/img/logo.png';
import logoBkImg from '../assets/img/logo_bk.png';
import appleImg from '../assets/img/app_img.png';
import apple2Img from '../assets/img/app_img2.png';
import googleImg from '../assets/img/google_img.png';
import googl2Img from '../assets/img/google_img2.png';
import cont01_mImg from '../assets/img/cont01_img_m.png';
import cont01_1Img from '../assets/img/cont01_img1.png';
import cont02_1Img from '../assets/img/cont02_img1.png';
import cont02_2Img from '../assets/img/cont02_img2.png';
import cont03Img from '../assets/img/cont03_img.png';
import cont03mImg from '../assets/img/cont03_img_m.png';
import cont04_1Img from '../assets/img/cont04_1.png';
import cont04_2Img from '../assets/img/cont04_2.png';
import cont04_3Img from '../assets/img/cont04_3.png';
import cont04_4Img from '../assets/img/cont04_4.png';

function HomePage() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);
  //스크롤 시 헤더 bg변경
  const [headerOn, setHeaderOn] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      // mobile
      setMobile(true);
    } else {
      // desktop
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector('.header').offsetHeight;
      const rollIt = window.scrollY >= headerHeight;
      setHeaderOn(rollIt);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="main_wr">
        <div className={headerOn ? 'header js-header on' : 'header js-header'}>
          <div className="containerWrap">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h1 className="logo" onClick={() => (window.location.href = '/')}>
                <img src={logoImg} alt="김회장마켓" className="logo_W" />
                <img src={logoBkImg} alt="김회장마켓" className="logo_bk_img" />
              </h1>
              <div className="down_btn btn_box">
                <button
                  type="button"
                  className="btn_main google_btn mr-3"
                  onClick={() =>
                    (window.location.href =
                      'https://play.google.com/store/apps/details?id=com.kimceo.mumbershipmarket')
                  }>
                  <img src={googleImg} className="google_img1" alt="이미지" />
                  <img src={googl2Img} className="google_img2" alt="이미지" />
                </button>
                <button
                  type="button"
                  className="btn_main apple_btn"
                  onClick={() =>
                    (window.location.href =
                      'https://apps.apple.com/us/app/%ED%9A%8C%EC%9B%90%EA%B6%8C%EB%A7%88%EC%BC%93-%ED%9A%8C%EC%9B%90%EA%B6%8C-%EA%B1%B0%EB%9E%98%EC%9D%98-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%95%9C-%EB%B0%A9%EB%B2%95/id6477554289')
                  }>
                  <img src={appleImg} className="app_img1" alt="이미지" />
                  <img src={apple2Img} className="app_img2" alt="이미지" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="containerWrap main_box">
          <div data-aos="fade-down">
            <p className="main_tit">
              '골프회원권시세 1등 애플리케이션' 회원권마켓
            </p>
            <p className="main_tit2">
              골프/콘도/헬스 회원권 정확한 시세와 정보전달 믿을 수 있는 파트너
            </p>
            <p className="main_sub_tit">
              정확한 시세와 매물을 전달하는 업체는 회원권 마켓이 유일합니다.
            </p>
            <div className="btn_box">
              <button
                type="button"
                className="btn_main google_btn"
                onClick={() => {
                  window.location.href =
                    'https://play.google.com/store/apps/details?id=com.kimceo.mumbershipmarket';
                }}>
                <img
                  alt="구글"
                  src={googleImg}
                  style={{maxHeight: '2.65rem', width: 'auto'}}
                />
              </button>
              <button
                type="button"
                className="btn_main apple_btn"
                onClick={() =>
                  (window.location.href =
                    'https://apps.apple.com/us/app/%ED%9A%8C%EC%9B%90%EA%B6%8C%EB%A7%88%EC%BC%93-%ED%9A%8C%EC%9B%90%EA%B6%8C-%EA%B1%B0%EB%9E%98%EC%9D%98-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%95%9C-%EB%B0%A9%EB%B2%95/id6477554289')
                }>
                <img
                  src={appleImg}
                  style={{maxHeight: '2.5rem', width: 'auto'}}
                  alt="이미지"
                />
              </button>
              {/* <div className="btn_box"> */}
              {/* {!mobile && ( */}
              <button
                type="button"
                className="btn_main quote_btn"
                onClick={() => navigate('/quotation')}>
                <p className="quote_txt">시세표 바로가기</p>
              </button>
              {/* )} */}

              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="cont01">
        <div className="containerWrap">
          <div className="cont01_txt" data-aos="fade-right">
            <p className="fs_48 fw_700">
              더 이상 수많은 업체들에게 전화해서 <span>시세와 매물을 체크</span>
              하지 않으셔도 됩니다.
            </p>
            <p className="fs_30 fw_300">
              회원권 마켓은 언제 어디서든 편하게 <span>매물검색</span>을 할 수
              있습니다.
            </p>
          </div>
          <div className="cont_img_m mt-3" data-aos="fade-left">
            <img src={cont01_mImg} alt="이미지" />
          </div>
        </div>
        <div className="cont_img_wr" data-aos="fade-left">
          <img src={cont01_1Img} alt="이미지" />
        </div>
      </div>

      <div className="cont02">
        <div className="containerWrap">
          <div className="cont02_wr cont02_top">
            <div data-aos="fade-right">
              <img src={cont02_1Img} alt="이미지" />
            </div>
            <div className="cont02_txt" data-aos="fade-left">
              <p className="cont02_tit wh_pre">
                구매자와 판매자가
                <span className="fw_700"> 매물과 시세를 한눈에</span>
              </p>
              <div className="line"></div>
              <p className="cont02_sub_tit wh_pre">
                희망가를 직접 입력하여 입찰하는 방식으로 더 이상 거래소와 입씨름
                할 필요가 없습니다.
              </p>
            </div>
          </div>
          <div className="cont02_wr cont02_bottom">
            <div className="cont02_txt" data-aos="fade-right">
              <p className="cont02_tit wh_pre">
                매물과 시세는 <span className="fw_700">스마트하게</span> 거래는{' '}
                <span className="fw_700">정확하게</span>
              </p>
              <div className="line"></div>
              <p className="cont02_sub_tit wh_pre">
                아직도 여기저기 거래소에 전화하세요? 이제 회원권마켓에서 정확한
                시세로 거래하세요!
              </p>
            </div>
            <div className="cont_img_wr2" data-aos="fade-left">
              <img src={cont02_2Img} alt="이미지" />
            </div>
          </div>
        </div>
      </div>

      <div className="cont03" data-aos="fade-down">
        <div className="containerWrap">
          <p className="cont03_tit">
            <span className="fw_800">구매</span>와
            <span className="fw_800">판매</span>는 어떻게 하나요?
          </p>
          <img src={cont03Img} className="pc" alt="이미지" />
          <img src={cont03mImg} className="m" alt="이미지" />
        </div>
      </div>

      <div className="cont04">
        <div className="containerWrap" data-aos="fade-down">
          <div className="d-flex align-items-center flex-wrap justify-content-center">
            <div className="cont04_cont">
              <img src={cont04_1Img} alt="이미지" />
              {/* <!-- <div className="cont04_txt">
                      <p className="p1">HOW TO BUY</p>
                      <p className="wh_pre">즉시결제 가능금액을 선택하면
                          매물 체크 후에 구매자에게 연락</p>
                      <p className="wh_pre mt-4">즉시체결 금액이 마음에 들지 않을 경우
                          구매 희망가를 입력하여
                          구매 입찰에 참여</p>
                  </div> --> */}
            </div>
            <div className="cont04_cont">
              <img src={cont04_2Img} alt="이미지" />
            </div>
            <div className="cont04_cont">
              <img src={cont04_3Img} alt="이미지" />
            </div>
            <div className="cont04_cont">
              <img src={cont04_4Img} alt="이미지" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HomePage;
