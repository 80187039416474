import React from 'react';
import '../styles/header.css';
function Header() {
  return (
    <div className="header2">
      <div className="containerWrap">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h1 className="logo" onClick={() => (window.location.href = '/')}>
            {/* <img
              src={require("../assets/img/logo.png")}
              alt="김회장마켓"
              className="logo_W"
            /> */}
            <img
              src={require('../assets/img/logo_bk.png')}
              alt="김회장마켓"
              className="logo_bk"
            />
          </h1>
          <div className="down_btn btn_box">
            <button
              type="button"
              className="btn google_btn mr-3"
              onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=com.kimceo.mumbershipmarket')
              }>
              <img
                src={require('../assets/img/google_img.png')}
                className="google_img1"
                alt="이미지"
              />
              <img
                src={require('../assets/img/google_img2.png')}
                className="google_img2"
                alt="이미지"
              />
            </button>
            <button
              type="button"
              className="btn apple_btn"
              onClick={() =>
                (window.location.href =
                  'https://apps.apple.com/us/app/%ED%9A%8C%EC%9B%90%EA%B6%8C%EB%A7%88%EC%BC%93-%ED%9A%8C%EC%9B%90%EA%B6%8C-%EA%B1%B0%EB%9E%98%EC%9D%98-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%95%9C-%EB%B0%A9%EB%B2%95/id6477554289')
              }>
              <img
                src={require('../assets/img/app_img.png')}
                className="app_img1"
                alt="이미지"
              />
              <img
                src={require('../assets/img/app_img2.png')}
                className="app_img2"
                alt="이미지"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
