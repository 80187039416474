import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

function PrivatyPage() {
  return (
    <div>
      <Header />
      <div className="container sub_page">
        <div>
          <h2 className="terms_tit">개인정보처리방침</h2>
          <div className="terms_cont">
            <p>
              <strong>㈜회원권마켓 &lsquo;개인정보처리방침&rsquo;</strong>
            </p>

            <p>안녕하세요!</p>

            <p>
              ㈜회원권마켓(이하&rdquo;회사&rdquo;)은 고객여러분의 개인정보의
              처리는 무엇보다 중요하며, 여러분의 재산을 안전하게 지키는 가장
              중요한 일 입니다. 여러분의 개인정보는 서비스의 원할한 제공을
              위하여 여러분이 동의한 목적과 범위내에서만 이용됩니다. 회사는
              &lsquo;개인정보보호법&rsquo;과 &lsquo;정보통신망이용촉진 및
              정보보호 등에 관한 법률&rsquo;등 모든 관련 법규를 준수하며
              회원님의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다.
              개인정보 보호정책을 통해 이용자가 제공한 개인정보가 어떠한 용도와
              방식으로 이용되며, 개인정보 보호를 위해 어떠한 방법을 통해
              관리되고 있는지에 대해 알려드립니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제1조 개인정보의 처리 목적</strong>
            </p>

            <p>
              회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
              개인정보는 다음의 목적이외의 용도록는 이용되지 않으며 이용목적이
              변경되는 경우에는&rsquo;개인정보 보호법&rsquo; 제18조에 따라
              별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>

            <p>1.회원가입 및 관리</p>

            <p>
              회원 가입의사확인, 회원제 서비스 제공에 따른 본인
              식별,인증,회원자격 유지.관리, 서비스 부정이용방지, 만14세미만
              아동의 개인정보처리 시 법정대리인의 동의여부 확인, 각종 고지.통지,
              고충처리 목적으로 개인정보를 처리합니다.
            </p>

            <p>2.계약관계를 위한 사무처리</p>

            <p>
              계약체결을 위한 본인확인 식별 및 확인, 사실조사를 위한 연락 및
              통지, 계약관계의 조사, 분쟁해결 및 민원처리, 고객만족도 조사의
              목적으로 개인정보를 처리합니다.
            </p>

            <p>3.마케팅 및 이벤트, 제품서비스 제공</p>

            <p>
              신규서비스의(제품)및 이벤트, 광고성 정보제공 및 참여기회 제공,
              인구통계학적 특성에 따른 서비스제공및 광고게제, 서비스의
              유효성확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계등을
              목적으로 개인정보를 처리합니다.
            </p>

            <p>4.개인식별이외에 거래매매에 기재된 정보 및 고객이 제공한정보</p>

            <p>
              회사명,회사주소,이메일,회사전화번호,직위,입사일,퇴사일,근무기간,가족사항,학력사항,자격사항,사업자의
              경우 등록증 사항등 개인식별이외에 고객이 제공한 정보를 거래매매를
              위한 목적으로 개인정보를 처리합니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제2조 개인정보의 처리 및 보유기간</strong>
            </p>

            <p>
              회사는 법령에 따른 개인정보 보유,이용기간 또는 정보주체로부터
              개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서
              개인정보를 처리합니다. 회사는 여러분이 서비스 이용과정에서 따로
              동의하는 경우나 법령에 규정된 경우를 제외하고는 여러분의
              개인정보를 위에서 말씀드린 목적 범위를 초과하여 이용하거나
              제3자에게 공유또는 제공되지 않습니다. 회사가 제3자의 서비스가
              연결되어 있는 경우 서비스 이용을 위해 필요한 범위내에서 여러분의
              동의를 얻은후에 개인정보를 제3자에게 제공할 수 있습니다.
            </p>

            <p>
              회사는 법령에 따른 개인정보 보유,이용기간 또는 이용자로부터
              개인정보를 수집시에는 동의받은 기간내에서 개인정보를
              처리.보유하며, 각각의 개인정보 처리 및 보유기간은 다음과 같습니다.
            </p>

            <p>*회원가입 및 관리, 탈회시까지 :</p>

            <p>
              -다만, 관계법령 위반에 따른 수사.조사 등이 진행중인 경우에는 해당
              수사.종료시까지
            </p>

            <p>-회사 내부 방침에 의한 정보보유 사유 :&nbsp;</p>

            <p>〮반복 재가입으로 인한 부정 이용방지-60일</p>

            <p>〮부정이용기록-5년</p>

            <p>*계약을 위한 매물 서비스제공 :</p>

            <p>
              회사는 고객의 계약 서비스를 돕기 위해 매물을 제공하고 거래가
              완료되면 매매에 대한 정보는 삭제되고 실거래가 서비스의 근거만 남게
              되며 고객의 개인정보는 삭제됩니다/
            </p>

            <p>
              다만, 이 외에 법령에따라 일정기간 보관해야 하는 경우는 아래와
              같습니다.
            </p>

            <p>-계약 또는 청약철회등에 관한 기록/보존 이유:</p>

            <p>전자상거래 등에서의 소비자보호에 관한 법률 보존기간-5년</p>

            <p>-대금결제 및 재화등의 공급에 관한 기록/보존 이유:</p>

            <p>전자상거래 등에서의 소비자보호에 관한 법률 보존기간-5년</p>

            <p>-소비자불만 또는 분쟁처리에 관한 기록/보존이유:</p>

            <p>전자상거래 등에서의 소비자보호에 관한 법률 보존기간-3년</p>

            <p>-표시,광고에 관한 기록/보존 이유 :</p>

            <p>전자금융거래법 보존기간 -5년</p>

            <p>-전자금융 거래에 관한 기록/보존 이유:</p>

            <p>전자금융거래법 보존기간 -5년</p>

            <p>-웹사이트 방문 기록/보존 이유:</p>

            <p>통신비밀보호법 보존기간-3개월</p>

            <p>-모든 거래에 관한 장부 및 증빙서류/보존 이유:</p>

            <p>국세기본법 보존기간 &ndash; 5년</p>

            <p>&nbsp;</p>

            <p>
              <strong>제3조 개인정보처리의 항목</strong>
            </p>

            <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>

            <p>1.회원 가입 및 관리</p>

            <p>&bull;성명, 생년월일, 주소, 전화번호, 이메일주소,&nbsp;</p>

            <p>2거래매물등록시 서비스 제공&nbsp;</p>

            <p>
              &bull;성명, 생년월일, 주소, 전화번호, 이메일주소, 회원권 보유현황,
              회원번호
            </p>

            <p>3.마케팅 및 이벤트 제공</p>

            <p>
              &bull;생년월일, 성명, 생일, 자택주소, 전화번호, 휴대번호, 이메일,
              회사명, 회사주소, 직위, 입사일,퇴사일, 근무기간,학력사항,사업자의
              경우 사업자등록증
            </p>

            <p>4.개인식별 이외에 거래를 위한 고객이 제공한 정보</p>

            <p>&nbsp;</p>

            <p>
              <strong>제4조 개인정보의 제3자 제공에 관한 사항</strong>
            </p>

            <p>
              1.회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한
              범위 내에서 만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
              「개인정보 보호법」 제17조 및 제18조에 해 당하는 경우에만
              개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를
              제3자에게 제공하지 않습니다.&nbsp;
            </p>

            <p>
              2.회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를
              얻어 필요 최소한의 범위로만 제공합니다.
            </p>

            <p>
              -계약 설정 유지 이행 관리 등에 필수적인 업무를 수행하는 제휴업체
              및 수탁/위탁업체에 제공 하고 있으며 관련법규에 따라 개인정보가
              안전하게 관리될 수 있도록 필요한 사항을 규정 하고 있습니다.
            </p>

            <p>
              *업체 : 웹,앱사이트관리 및 시스템관리 -회원권마켓, 회원권협회에
              등록된거래소,디몬스터(앱관리),골프장경영협회
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제5조 개인정보 처리 업무의 위탁에 관한 사항</strong>
            </p>

            <p>
              회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
              처리업무를 위탁하고 있습니다.&nbsp;
            </p>

            <p>
              *제공받는 자 : 디몬스터 (웹&amp;앱 시스템관리), 회원권마켓,
              회원권협회에 등록된거래소,골프장경영협회
            </p>

            <p>*보유 및 이용기간 : 회원탈퇴 또는 위탁 및 수탁 계약 종료 시</p>

            <p>
              위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
              처리방침을 통하여 공개 하도록 하겠습니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제6조 개인정보의 파기절차 및 방법에 관한 사항</strong>
            </p>

            <p>
              1.회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 단,
              제2조 개인정보의 처리 및 보유기간에서 명시한 사항에 해당하는
              경우에는 예외를 두고 있습니다.
            </p>

            <p>
              2.정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리
              목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
              보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터
              베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.&nbsp;
            </p>

            <p>3.개인정보 파기의 절차 및 방법은 다음과 같습니다.&nbsp;</p>

            <p>
              -파기 절차는 파기 사유가 발생한 개인정보를 선정하고, 개인 정보
              보호책임자의 승인을 받아 개인정보를 파기합니다.&nbsp;
            </p>

            <p>
              -파기방법 은(는) 전자적 파일 형태로 기록&middot;저장된 개인정보는
              기록을 재생할 수 없도록 파기하며, 종이 문서에 기록&middot;저장된
              개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>
                제7조 정보주체와 법정대리인의 권리.의무 및 행사방법에 관한 사항
              </strong>
            </p>

            <p>
              1.정보주체는 에 대해 언제든지 개인정보
              열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를
              행사할 수 있습니다.&nbsp;
            </p>

            <p>
              ※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이
              직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의
              개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을
              통하여 권리를 행사할 수도 있습니다.)
            </p>

            <p>
              2.권리 행사는 에 대해 「개인정보 보호법」 시행령 제41조 제1항에
              따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며,
              은(는) 이에 대해 지체없이 조치하겠습니다.&nbsp;
            </p>

            <p>
              3.권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
              통하여 하실 수도 있습니다. 이 경우 &ldquo;개인정보 처리 방법에
              관한 고시(제2020-7호)&rdquo; 별지 제11호 서식에 따른 위임장을
              제출하셔야 합니다.&nbsp;
            </p>

            <p>
              4.개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
              제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
              있습니다.&nbsp;
            </p>

            <p>
              5.개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
              없습니다.&nbsp;
            </p>

            <p>
              6.회사는 정보주체 권리에 따른 열람의 요구, 정정&middot;삭제의
              요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>
                제8조 개인정보의 안정성 확보 조치에 관한 사항&nbsp;
              </strong>
            </p>

            <p>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
            </p>

            <p>1.정기적인 자체 감사 실시.</p>

            <p>
              개인정보 취급관련 안전성 확보를 위해 정기적(분기1회)으로 자체
              감사를 실시하고 있습니다.
            </p>

            <p>2.개인정보 취급 직원의 최소화 및 교육</p>

            <p>
              개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여
              개인 정보를 관리하는 대책을 시행하고 있습니다.
            </p>

            <p>3.내부관리 계획의 수립 및 시행</p>

            <p>
              개인정보의 안전한 처리를 위해 내부관리 계획을 수립하고 시행하고
              있습니다.
            </p>

            <p>4.개인정보의 암호화</p>

            <p>
              이용자의 개인정보 비밀번호는 암호화되어 저장 및 관리되어 있고,
              본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
              하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
              있습니다.
            </p>

            <p>5.문서보안을 위한 잠금장치 사용</p>

            <p>
              개인정보가 포함된 서류,보조저장매체 등을 잠금장치가 있는 안전한
              장소에 보관하고 있습니다.
            </p>

            <p>6.비인가 자에 대한 출입통제</p>

            <p>
              개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
              출입통제 절차를 수립,운영 하고 있습니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제9조 개인정보 보호책임자에 관한 사항</strong>
            </p>

            <p>
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
              같이 개인정보 보호책임자를 지정하고 있습니다.&nbsp;
            </p>

            <p>*개인정보 보호책임자&nbsp;</p>

            <p>-성명 : 고재성</p>

            <p>-직책 : 대표</p>

            <p>-연락처 : 010-4362-9007</p>

            <p>*부 담당자 총괄 : 구진호 부장 010-9011-2190</p>

            <p>
              정보주체자는 회사의 서비스를 이용하면서 불만사항이나 개인정보관련
              문의 및 피해구제등에 관한 사항을 개인정보보호 책임자 또는
              부담당자에게 문의 하실 수 있습니다. 회사는 정보주체의 문의에
              성실히 답변 드리고 처리해 드릴 것 입니다.
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제10조 권익침해 구조방법</strong>
            </p>

            <p>
              1.정보주체는 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.&nbsp;
            </p>

            <p>
              -개인정보분쟁조정위원회 : (국번없이) 1833-6972
              (www.kopico.go.kr)&nbsp;
            </p>

            <p>
              -개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)&nbsp;
            </p>

            <p>-대검찰청 : (국번없이) 1301 (www.spo.go.kr)&nbsp;</p>

            <p>-경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)&nbsp;</p>

            <p>
              2.회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로
              인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이
              필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.&nbsp;
            </p>

            <p>개인정보보호 관련 고객 상담 및 신고&nbsp;</p>

            <p>부서명 : 총괄</p>

            <p>담당자 : 고재성</p>

            <p>연락처 : 010-4362-9007</p>

            <p>
              3.「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의
              정정&middot;삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한
              요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리
              또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라
              행정심판을 청구할 수 있습니다.&nbsp;
            </p>

            <p>
              ‣ 중앙행정심판위원회 : (국번없이) 110 (
              <a
                href="http://www.simpan.go.kr/"
                rel="noopener noreferrer"
                target="_blank">
                www.simpan.go.kr
              </a>
              )
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>제11조 개인정보 처리 방침의 변경에 관한 사항</strong>
            </p>

            <p>이 개인정보 처리방침은 2024년 03월01일부터 적용됩니다.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivatyPage;
