import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Page/HomePage";
import TermPage from "./Page/TermPage";
import PrivatyPage from "./Page/PrivatyPage";
import QuotePage from "./Page/QuotePage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/terms_use" element={<TermPage />}></Route>
          <Route path="/terms_private" element={<PrivatyPage />}></Route>
          <Route path="/quotation" element={<QuotePage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
